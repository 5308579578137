<template>
  <div class="outside">
    <!-- <banner /> -->
    <div class="top-banner">
      <img src="../../assets/img/home/banner3.jpg" alt="" srcset="">
    </div>
    <div class="introduction">
      <div class="edition_heart">
        <div class="title">专家论证会</div>
        <div class="titleEng">Expert demonstration meeting</div>
        <div class="img">
          <el-row :gutter="20">
            <el-col :span="8" v-for="(item, index) in imgData" :key="index">
              <img :src="item.imgUrl" alt="" @click="amplify(item.imgUrl)" />
            </el-col>
          </el-row>
        </div>
        <div class="pagination">
          <pagination :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" />
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="open" append-to-body>
      <img :src="amplifyImg" style="width: 100%;" />
    </el-dialog>
  </div>
</template>
<script>
import banner from '/src/components/banner.vue'
import Pagination from '/src/components/Pagination/index.vue'

export default {
  name: 'expertDemonstration',
  components: { banner, Pagination },
  data() {
    return {
      imgData: [
        {
          imgUrl: require('/src/assets/img/expertDemonstration/meeting1.jpg'),
        },
        {
          imgUrl: require('/src/assets/img/expertDemonstration/meeting2.jpg'),
        },
        {
          imgUrl: require('/src/assets/img/expertDemonstration/meeting3.jpg'),
        },
        {
          imgUrl: require('/src/assets/img/expertDemonstration/meeting4.jpg'),
        },
        {
          imgUrl: require('/src/assets/img/expertDemonstration/meeting5.jpg'),
        },
        {
          imgUrl: require('/src/assets/img/expertDemonstration/meeting6.jpg'),
        },
        {
          imgUrl: require('/src/assets/img/expertDemonstration/meeting7.jpg'),
        },
        {
          imgUrl: require('/src/assets/img/expertDemonstration/meeting8.jpg'),
        },
        {
          imgUrl: require('/src/assets/img/expertDemonstration/meeting9.jpg'),
        },
      ],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      open: false,
      amplifyImg: null,
    }
  },
  methods: {
    amplify(url) {
      this.open = true
      this.amplifyImg = url
    },
  },
}
</script>

<style lang="scss" scoped>
.outside {
  background-color: #F9F7F3;
}

.top-banner {
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    min-width: 1920px;
    height: 400px !important;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #A40000;
}

@media screen and (min-width: 921px) {
  .edition_heart {
    width: 62.5%;
  }

  .outside {
    padding-top: 120px;

    .introduction {
      .title {
        padding-top: 80px;
      }
    }

    img {
      width: 100%;
      height: 260px;
      margin-bottom: 15px;
    }

    img:hover {
      cursor: zoom-in;
    }

    .pagination {
      display: flex;
      justify-content: center;
      padding: 0 0 70px;
    }
  }

  /deep/.el-dialog {
    margin-top: 25vh !important;
    z-index: 999;
    width: 880px;
  }
}

@media screen and (min-width: 768px) and (max-width: 920px) {
  .edition_heart {
    width: 80%;
  }

  .outside {
    padding-top: 120px;

    .introduction {
      .title {
        padding-top: 80px;
      }
    }

    img {
      width: 100%;
      height: 260px;
      margin-bottom: 15px;
    }

    img:hover {
      cursor: zoom-in;
    }

    .pagination {
      display: flex;
      justify-content: center;
      padding: 20px 0 70px;
    }
  }

  /deep/.el-dialog {
    margin-top: 25vh !important;
    z-index: 999;
    width: 880px;
  }
}

@media screen and (max-width: 768px) {
  .top-banner {
    display: none;
  }

  .edition_heart {
    width: 90%;
  }

  .outside {
    padding-top: 90px;

    .introduction {
      img {
        width: 100%;
        height: 120px;
        margin-bottom: 20px;
      }

      .pagination {
        display: none;
      }
    }
  }

  /deep/.el-dialog {
    margin-top: 25vh !important;
    z-index: 999;
    width: 400px;
  }
}

* {
  margin: 0;
  padding: 0;
}

.outside {
  .introduction {
    width: 100%;
    height: auto;

    .title {
      font-size: 32px;
      font-family: PingFang SC, PingFang SC-Heavy;
      font-weight: 800;
      text-align: left;
      color: #333;
    }

    .titleEng {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: rgba(102, 102, 102, .4);
      line-height: 30px;
      padding-bottom: 18px;
      border-bottom: 1px solid #D8D8D8;
      position: relative;
    }

    .titleEng::before {
      content: "";
      display: block;
      width: 109px;
      height: 6px;
      background: #A40000;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .img {
      padding: 50px 0 0;
    }
  }

  // 版心
  .edition_heart {
    // width: 62.5%;
    margin: 0px auto;
    position: relative;
  }
}
</style>
